import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>河南紫瑞天成物联网技术有限公司</p>
            </span>
          </span>
        ),
        className: 'banner5-title lyd53i6fo7-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content lyd53vjtux-editor_css',
        children: (
          <span>
            <p>主页建设中，敬请期待。</p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          ©2021 河南紫瑞天成物联网技术有限公司&nbsp;All Rights
                          Reserved&nbsp;{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>豫ICP备2022027831号
        </span>
      </span>
    ),
  },
};
