
    import React from 'react';
    import { dynamic } from 'dumi';
    import { Link, AnchorLink, context } from 'dumi/theme';
    import Alert from 'dumi-theme-default/es/builtins/Alert.js';
import API from 'dumi-theme-default/es/builtins/API.js';
import Badge from 'dumi-theme-default/es/builtins/Badge.js';
import Example from 'dumi-theme-default/es/builtins/Example.js';
import Previewer from 'dumi-theme-default/es/builtins/Previewer.js';
import SourceCode from 'dumi-theme-default/es/builtins/SourceCode.js';
import Table from 'dumi-theme-default/es/builtins/Table.js';
import Tree from 'dumi-theme-default/es/builtins/Tree.js';

    // memo for page content, to avoid useless re-render since other context fields changed
    const PageContent = React.memo(({ demos: DUMI_ALL_DEMOS }) => {
      const DumiDemo1 = (require('D:/ZRTC/docs/Home/index.jsx')).default

      return (
        <>
          
          <DumiDemo1 />
        </>
      );
    })

    export default (props) => {
      const { demos } = React.useContext(context);

      // scroll to anchor after page component loaded
      React.useEffect(() => {
        if (props?.location?.hash) {
          AnchorLink.scrollToAnchor(decodeURIComponent(props.location.hash.slice(1)));
        }
      }, []);

      return <PageContent demos={demos} />;
  }